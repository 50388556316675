import productDb from '../productDb';
import Products from "./Products";
import CategoryHeader from "./CategoryHeader";

import {CATEGORIES} from "../utils";
import {GENDERS} from "../utils";

import {useState} from "react";
import SectionHeader from "./SectionHeader";
import AppWithoutFooter from "../AppWithoutFooter";


export default function Section(props) {
    const { title, subtitle, primaryFilters, linkUrl } = props;
    // const navigate = useNavigate();
    // const {cart, updateState} = useContext(UserContext);
    // const [ paymentDetails, setPaymentDetailes ] = useState({
    //     isPaymentDetailsStep: false
    // })

    const [pickedFilters, setPickedFilters] = useState(primaryFilters);


    return (
            <SectionHeader
                title={title}
                subtitle={subtitle}
                linkUrl={linkUrl}
                pickedFilters={ pickedFilters }
                setPickedFilters={ setPickedFilters }
                products={ productDb }
                simpleFunction={() => {  }}
            >
                <Products
                    products={
                        productDb
                            .filter(product => {
                                    if (!pickedFilters.length) {
                                        return true;
                                    }

                                    for (let i = 0; i < pickedFilters.length; i++) {
                                         if (pickedFilters[i].type == 'category' && !product.categories.includes(pickedFilters[i].value)) {
                                        return false
                                        }
                                    }
                                    return true;
                                }
                            )
                    }
                />
            </SectionHeader>
    );
}
