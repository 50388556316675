import balaclava_1 from './images/balaclava/balaclava_1.jpg';
import balaclava_2 from './images/balaclava/balaclava_2.jpg';
import vm_pink_1 from './images/pink-dress/vm_pink_1.JPG';
import vm_pink_2_small from './images/pink-dress/vm_pink_2.JPG';
import vm_pink_3 from './images/pink-dress/vm_pink_3.JPG';
import vm_pink_4_small from './images/pink-dress/vm_pink_4.JPG';
import boglioli_jacket_1 from './images/jacket/boglioli_jacket_1.jpg';
import boglioli_jacket_2 from './images/jacket/boglioli_jacket_2.jpg';
import boglioli_jacket_3 from './images/jacket/boglioli_jacket_3.jpg';
import cloud1 from './images/cloud-hat/cloud-hat-1.jpg';
import cloud2 from './images/cloud-hat/cloud-hat-2.jpg';
import cloud3 from './images/cloud-hat/cloud-hat-3.jpg';
import cloud4 from './images/cloud-hat/cloud-hat-4.jpg';
import cloud5 from './images/cloud-hat/cloud-hat-5.jpg';
import bag1 from './images/bag/bag-1.jpg';
import bag2 from './images/bag/bag-2.jpg';
import bag3 from './images/bag/bag-3.jpg';
import green_dress_1 from './images/green-dress/green_dress_1.JPG';
import green_dress_2 from './images/green-dress/green_dress_2.JPG';
import green_dress_3 from './images/green-dress/green_dress_3.jpg';
import green_dress_4 from './images/green-dress/green_dress_4.jpg';
import green_dress_5 from './images/green-dress/green_dress_5.jpg';
import {CATEGORIES, GENDERS, SEASONS} from "./utils";

export const colorNameToCode = {
    "Розовый": "#ff8499",
    "Белый": "#ffffff",
    "Черный": "#000000",
    "Зеленый": "#007b14"
}

const productsProd = [
    {
        id: 1,
        modelId: 1,
        imageList: [
            vm_pink_2_small, vm_pink_1, vm_pink_4_small, vm_pink_3
        ],
        productSrc: "",
        description: "Платье шорты из итальянской вискозы пудрового цвета",
        title: "Платье шорты Lily",
        price: "6990 руб",
        currency: "руб",
        size: "42",
        color: "Розовый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 2,
        modelId: 1,
        imageList: [
            vm_pink_2_small, vm_pink_1, vm_pink_4_small, vm_pink_3
        ],
        productSrc: "",
        description: "Платье шорты из итальянской вискозы пудрового цвета",
        title: "Платье шорты Lily",
        price: "6990 руб",
        currency: "руб",
        size: "44",
        color: "Розовый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 3,
        modelId: 1,
        imageList: [
            vm_pink_2_small, vm_pink_1, vm_pink_4_small, vm_pink_3
        ],
        productSrc: "",
        description: "Платье шорты из итальянской вискозы пудрового цвета",
        title: "Платье шорты Lily",
        price: "6990 руб",
        currency: "руб",
        size: "46",
        color: "Розовый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 51,
        modelId: 2,
        imageList: [
            green_dress_1, green_dress_2,green_dress_3, green_dress_4, green_dress_5
        ],
        productSrc: "",
        description: "Платье из итальянской вискозы зеленого цвета",
        title: "Платье Mirror",
        price: "6990 руб",
        currency: "руб",
        size: "42",
        color: "Зеленый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 52,
        modelId: 2,
        imageList: [
            green_dress_1, green_dress_2,green_dress_3, green_dress_4, green_dress_5
        ],
        productSrc: "",
        description: "Платье из итальянской вискозы зеленого цвета",
        title: "Платье Mirror",
        price: "6990 руб",
        currency: "руб",
        size: "44",
        sizes:[],
        color: "Зеленый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 53,
        modelId: 2,
        imageList: [
            green_dress_1, green_dress_2,green_dress_3, green_dress_4, green_dress_5
        ],
        productSrc: "",
        description: "Платье из итальянской вискозы зеленого цвета",
        title: "Платье Mirror",
        price: "6990 руб",
        currency: "руб",
        size: "46",
        sizes:[],
        color: "Зеленый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        colors: [],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    }
];

const productsInt = [
    {
        id: 1,
        imageList: [
            balaclava_1, balaclava_2
        ],
        productSrc: "hello",
        description: "Travel bag in H Plume canvas with \"Circuit 24\" motif, shoulder strap, collapsible handle, exterior pocket and Clou de Selle closure\n" +
            "\n" +
            "Сделано в Москве\n" +
            "\n" +
            "Metallic finish: Palladium plated\n" +
            "\n" +
            "Measures 19.7\" long, 13.4\" high and 8.9\" deep",
        title: "Шерстяная балаклава",
        price: "3990 руб",
        sizes: ["42", "44", "46"],
        size: "42",
        color: "Черный",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.ACCESSORIES],
        seasons: [SEASONS.SS],
        related: [],
        colors: [1, 2],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "3990 руб", level: "secondary" },
            { type: "title", value: "Шерстяная балаклава", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
            { type: "location", value: [{date: "", location: ""}], level: "secondary" },
        ]
    },
    {
        id: 2,
        imageList: [
            vm_pink_2_small, vm_pink_4_small
        ],
        productSrc: "hello",
        description: "description",
        title: "Платье шорты Лилия",
        color: "Белый",
        price: "6990 руб",
        sizes: ["42", "44", "46"],
        size: "42",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        related: [],
        colors: [1,2],
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "3990 руб", level: "secondary" },
            { type: "title", value: "Платье шорты Лилия", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 3,
        imageList: [
            vm_pink_2_small, vm_pink_4_small
        ],
        productSrc: "hello",
        description: "description",
        color: "Розовый",
        title: "Платье шорты Лилия",
        sizes: ["42", "44", "46"],
        size: "47",
        colors: [],
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        related: [],
        price: "6990 руб",
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "3990 руб", level: "secondary" },
            { type: "title", value: "Платье шорты Лилия 2", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 4,
        imageList: [
            boglioli_jacket_1, boglioli_jacket_2, boglioli_jacket_3
        ],
        productSrc: "hello",
        description: "description",
        color: "Розовый",
        title: "Пиджак из шерсти",
        sizes: ["52"],
        size: "47",
        colors: [],
        genders: [GENDERS.MEN],
        categories: [CATEGORIES.JACKETS],
        seasons: [SEASONS.SS],
        model: 12345678,
        related: [],
        price: "16990 руб",
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 5,
        imageList: [
            cloud1, cloud2, cloud3, cloud4, cloud5
        ],
        productSrc: "hello",
        description: "description",
        color: "Розовый",
        title: "Пиджак из шерсти",
        sizes: ["52"],
        size: "47",
        colors: [],
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.ACCESSORIES],
        seasons: [SEASONS.FW],
        model: 12345678,
        related: [],
        price: "16990 руб",
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
    {
        id: 6,
        imageList: [
            bag1, bag2, bag3
        ],
        productSrc: "hello",
        description: "description",
        color: "Розовый",
        title: "Пиджак из шерсти",
        sizes: ["52"],
        size: "47",
        colors: [],
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.ACCESSORIES],
        seasons: [SEASONS.FW],
        model: 12345678,
        related: [],
        price: "16990 руб",
        traits: [
            { type: "gender", value: GENDERS.WOMEN, level: "primary" },
            { type: "category", value: CATEGORIES.ACCESSORIES, level: "primary" },
            { type: "season", value: SEASONS.SS, level: "primary" },
            { type: "size", value: 42, level: "secondary" },
            { type: "model", value: 111222, level: "secondary" },
            { type: "color", value: "Черный", level: "secondary" },
            { type: "price", value: "16990 руб", level: "secondary" },
            { type: "title", value: "Пиджак из шерсти", level: "secondary" },
            { type: "description", value: "", level: "secondary" },
        ]
    },
]

const products = productsProd;

export default products;

