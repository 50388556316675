import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import img from '../img.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { UserContext } from '../UserContext';

import { addCartItem } from '../utils';


function MenuPart(props) {
    const {title = '', items = []} = props;
    const { cart, updateState } = useContext(UserContext);

    return (
        <Grid container spacing={2} pb={12}>
            <Grid item xs={12} md={12} lg={12}
                  justifyContent="start"
                  alignItems="center"
            >
                <Typography variant={'h3'}>{title}</Typography>
            </Grid>
            { items.map(it =>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            title={it.title}
                        />
                        <CardMedia
                            component="img"
                            height="194"
                            image={it.imageSrc}
                            alt="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {it.text}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Stack direction='row' sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}  >
                                    <Typography variant="h6" color="text.secondary">
                                        {it.price} рублей
                                    </Typography>
                                    <IconButton aria-label="add"
                                                onClick={() => {
                                                    it.count = 1;
                                                    updateState({ cart: { ...cart, items: addCartItem(cart.items, it) }})
                                                }}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </CardActions>
                    </Card>

                </Grid>
            )}
        </Grid>
    );
}

export default function About() {
   const handleMouseEnter = () => {
   }

    const handleMouseLeave = () => {
    }

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
        //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
          {/*<Box>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        sx={{ mb: 4}}*/}
          {/*        variant='h2'>*/}
          {/*        О нас*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        variant='h4'>*/}
          {/*    Концепция*/}
          {/*    </Typography>*/}
          {/*    <Typography align='left' sx={{mb: 2}}>*/}
          {/*      Особое место в нашем меню занимают блюда, которые называют фастфудом. Мы искренне любим эти блюда. Поэтому каждый день мы стараемся сделать наши блюда все более эстетичными, сбалансированными и безопасными.*/}
          {/*    </Typography>*/}

          {/*    <Typography align='left'>*/}
          {/*    <b>Эстетичными.</b> Мы верим, что, например, бургер можеть быть приготовлен  и упакован таким образом, что его не стыдно будет подать к вину; а формфактор позволит лего и чисто съесть его целиком.*/}
          {/*      </Typography>*/}
          {/*    <Typography align='left'>*/}
          {/*    <b>Сбалансированными,</b> потому что все ингредиенты вместе должны сочетаться гармонично.*/}
          {/*    </Typography>*/}

          {/*    <Typography align='left'>*/}
          {/*        <b>Безопасными.</b> Мы считаем, что в питании -  это абсолютный приоритет.*/}
          {/*    </Typography>*/}

          {/*    <Typography align='left'>*/}
          {/*    Эстетика, сбалансированность и безопасность наших блюд достигаются нашими принципами:*/}
          {/*    </Typography>*/}

          {/*    <ul>*/}
          {/*       <li>*/}
          {/*           <Typography align='left'>*/}
          {/*           Готовить блюда не только вкусыми, но и эстетичными.*/}
          {/*           </Typography>*/}
          {/*       </li>*/}
          {/*        <li>*/}
          {/*            <Typography align='left'>*/}
          {/*            Разрабатывать позиции меню с “правильным” соотношением питательных веществ, контролем содеражния количества сахара, жиров и пещевых волокон.*/}
          {/*            </Typography>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*            <Typography align='left'>*/}
          {/*            Пищевая безопасность - первоочередное. Готовить так как готовили бы для свиох семей.*/}
          {/*            </Typography>*/}
          {/*        </li>*/}
          {/*    </ul>*/}

          {/*    <Typography align='left'>*/}
          {/*      Поэтому мы очень много времени уделяем отбору поставщиков, пристальному контролю за соблюдением норм санитарной безопасности, проработке блюд и постоянному обучению сотрудников.*/}
          {/*    </Typography>*/}
          {/*    <br/>*/}
          {/*    <div style={{position:"relative", overflow: "hidden"}}><a*/}
          {/*        href="src/components/About?utm_medium=mapframe&utm_source=maps"*/}
          {/*        style={{color:"#eee",fontSize:"12px",position:"absolute",top:"0px"}}>Москва</a><a*/}
          {/*        href="src/components/About?ll=37.562490%2C55.743601&utm_medium=mapframe&utm_source=maps&z=16.32"*/}
          {/*        style={{color:"#eee",fontSize:"12px",position:"absolute",top:"14px"}}>Киевская улица, 2 — Яндекс Карты</a>*/}
          {/*        <iframe*/}
          {/*            src="src/components/About?ll=37.562490%2C55.743601&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgk3NzM2Mzg2MjUSOtCg0L7RgdGB0LjRjywg0JzQvtGB0LrQstCwLCDQmtC40LXQstGB0LrQsNGPINGD0LvQuNGG0LAsIDIiCg05QBZCFU_5XkI%2C&z=16.32"*/}
          {/*            width="100%" height="400" frameBorder="1" allowFullScreen="true"*/}
          {/*            style={{position:"relative"}}></iframe>*/}
          {/*    </div>*/}
          {/*    <Typography variant='h6'>Часы работы с 10:00 до 20:00 каждый день</Typography>*/}
          {/*</Box>*/}

      </Container>
    </Box>
  );
}
