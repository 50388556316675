import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";


export default function Style() {
   const handleMouseEnter = () => {
   }

    const handleMouseLeave = () => {
    }

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
        //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
          <Typography>Стилизуем вашу съемку один раз или займемся вашим стилем на постоянной основе</Typography>
          {/*<Box>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        sx={{ mb: 4}}*/}
          {/*        variant='h2'>*/}
          {/*        Доставка и оплата*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        variant='h4'>*/}
          {/*    Режим работы*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        >*/}
          {/*        Пн – вс С 10:00 до 20:00*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        variant='h4'>*/}
          {/*        Варианты получения*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        >*/}
          {/*        Доставка в пределах МКАД стоит 300 рублей. Сделайте заказ и получите его в течение 60 минут по указанному адресу.*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        >*/}
          {/*        Также у нас есть самовывоз в одной минуте от метро Киевская. Адрес - Киевская ул., дом 2. Так как заказ у нас готовится в течение 10 минут - это очень удобная опция пообедать или перекусить, если находитесь на Киевской.*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        variant='h4'>*/}
          {/*        Оплата*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*        align='left'*/}
          {/*        >*/}
          {/*        Оплата производится банковскими картами Visa, MasterCard, Maestro и МИР.  Безопасность обработки интернет-платежей по пластиковым картам гарантирует банк-эквайер АО «Тинькофф Банк». Счет со ссылкой на оплату выставляется после подтверждения заказа нашим менеджером. Все операции с картами происходят в соответствии с требованиями международных платежных систем. Все данные платежных карт передаются только в зашифрованном виде и не сохраняются. Предоставляемая Вами персональная информация является конфиденциальной и не подлежит разглашению.*/}
          {/*    </Typography>*/}




          {/*    <br/>*/}
          {/*    /!*<div style={{position:"relative", overflow: "hidden"}}><a*!/*/}
          {/*    /!*    href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"*!/*/}
          {/*    /!*    style={{color:"#eee",fontSize:"12px",position:"absolute",top:"0px"}}>Москва</a><a*!/*/}
          {/*    /!*    href="https://yandex.ru/maps/213/moscow/house/kiyevskaya_ulitsa_2/Z04YcwFiTkcGQFtvfXt1cnhlYg==/?ll=37.562490%2C55.743601&utm_medium=mapframe&utm_source=maps&z=16.32"*!/*/}
          {/*    /!*    style={{color:"#eee",fontSize:"12px",position:"absolute",top:"14px"}}>Киевская улица, 2 — Яндекс Карты</a>*!/*/}
          {/*    /!*    <iframe*!/*/}
          {/*    /!*        src="https://yandex.ru/map-widget/v1/?ll=37.562490%2C55.743601&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgk3NzM2Mzg2MjUSOtCg0L7RgdGB0LjRjywg0JzQvtGB0LrQstCwLCDQmtC40LXQstGB0LrQsNGPINGD0LvQuNGG0LAsIDIiCg05QBZCFU_5XkI%2C&z=16.32"*!/*/}
          {/*    /!*        width="100%" height="400" frameBorder="1" allowFullScreen="true"*!/*/}
          {/*    /!*        style={{position:"relative"}}></iframe>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    /!*<Typography variant='h6'>Часы работы с 10:00 до 20:00 каждый день</Typography>*!/*/}
          {/*</Box>*/}

      </Container>
    </Box>
  );
}
