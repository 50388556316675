import productDb from '../productDb';
import Products from "./Products";
import App from '../App';
import CategoryHeader from "./CategoryHeader";

import {CATEGORIES} from "../utils";
import {GENDERS} from "../utils";

import {useState} from "react";


export default function CategoryPage(props) {
    const { title, subtitle, primaryFilters } = props;
    // const navigate = useNavigate();
    // const {cart, updateState} = useContext(UserContext);
    // const [ paymentDetails, setPaymentDetailes ] = useState({
    //     isPaymentDetailsStep: false
    // })

    const [pickedFilters, setPickedFilters] = useState(primaryFilters);


    return (
        <App>
            <CategoryHeader
                title={title}
                subtitle={subtitle}
                pickedFilters={ pickedFilters }
                setPickedFilters={ setPickedFilters }
                products={ productDb }
                simpleFunction={() => {  }}
            >
                <Products
                    products={
                        productDb
                            .filter(product => {
                                    if (!pickedFilters.filter((fltr) => fltr.level != "primary").length) {
                                        return true;
                                    }


                                    let sizeFlag = false;
                                    let colorFlag = false;
                                    for (let i = 0; i < pickedFilters.length; i++) {
                                         // if (pickedFilters[i].type == 'category' && !product.categories.includes(pickedFilters[i].value)) { return false }

                                         if (pickedFilters[i].type == 'size' && product.size == pickedFilters[i].value) {
                                             sizeFlag = true;
                                         }

                                         if (pickedFilters[i].type == 'color' && product.color == pickedFilters[i].value) {
                                             colorFlag = true;
                                         }
                                    }

                                    return (sizeFlag || !pickedFilters.filter(fltr => fltr.type == 'size').length) &&
                                    (colorFlag || !pickedFilters.filter(fltr => fltr.type == 'color').length);
                                }
                            )
                    }
                />
            </CategoryHeader>
        </App>
    );
}
