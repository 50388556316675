import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";


const SectionHeader = (props) => {
    const { children, title, linkUrl } = props;
    const navigate = useNavigate();

  return (
      <Box
          sx={(theme) => ({
              width: '100%',
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
          })}
      >
          {/*<Container*/}
          {/*    container*/}
          {/*>*/}
          {/*</Container>*/}
          <Box
              sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  // pt: { xs: 8, sm: 8 },
                  // pb: { xs: 8, sm: 12 },
              }}
          >
              <Box
                  sx={{
                      width: '100%'
                  }}
              >
                  <Container>
                      <Container>
                          <Typography variant='categorySubtitle1' align='center' width='100%'>
                              {title}
                          </Typography>
                      </Container>
                      <Container
                          sx={{
                              pb: 2,
                              pt: 2,
                              textDecoration: 'underline',
                              textUnderlineOffset: '10px',
                              '&:hover': {
                                  cursor: 'pointer'
                              },
                          }}
                          onClick={() => { navigate(linkUrl) }}
                      >
                          <Typography variant='body1' align='center' width='100%'>
                              {`Посмотреть все`}
                          </Typography>
                      </Container>
                  </Container>
                  <Container
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                          // pt: { xs: 14, sm: 20 },
                          // pb: { xs: 8, sm: 12 },
                      }}
                  >
                      { children }
                  </Container>
              </Box>
          </Box>
      </Box>
  );
}

export default SectionHeader;
